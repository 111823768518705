import { db } from "../indexedDB";
import store from "../store";
import axiosInstance from "../axiosInstance";

const API_URL = process.env.VUE_APP_SERVICE_URL + "/api/staff";
const STORE_NAME = "staff";

export const fetchAndStoreStaff = async () => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/all?is_paginated=false`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        const data = response.data.data;
        // Clear old data and store new data
        await db.clearStore(STORE_NAME);
        data.forEach((item) => db.addItem(STORE_NAME, item));
        return data;
      }
    } catch (error) {
      console.error(`Failed to fetch ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getAllItems(STORE_NAME);
  }
};

export const getStaff = async (id) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/${id}`;
    const params = {};
    const headers = {};
    const method = "get";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(`Failed to get ${STORE_NAME}:`, error);
      store.commit("setError", { isError: true, error: error });
    }
  } else {
    return await db.getItem(STORE_NAME, id);
  }
};

export const addStaff = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/new`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        await fetchAndStoreStaff();
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to create ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to create ${STORE_NAME}:`, error);
    }
  } else {
    // Store the change for later
    await db.addOfflineChange({ type: "addStaff", STORE_NAME, item });
    await db.addItem(STORE_NAME, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  }
};

export const editStaff = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/edit`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        await fetchAndStoreStaff();
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to edit ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to edit ${STORE_NAME}:`, error);
    }
  } else {
    // Store the change for later
    await db.addOfflineChange({ type: "editStaff", STORE_NAME, item });
    await db.editItem(STORE_NAME, item.uuid, item);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  }
};

export const deleteStaff = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/delete`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        await fetchAndStoreStaff();
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to delete ${STORE_NAME}: ${error}`,
      });
      console.error(`Failed to delete ${STORE_NAME}:`, error);
    }
  } else {
    // Store the change for later
    await db.addOfflineChange({ type: "deleteStaff", STORE_NAME, item });
    await db.deleteItem(STORE_NAME, item.uuid);
    store.commit("setError", {
      isError: false,
      error: `Your changes are offline.`,
    });
    return "success";
  }
};

export const syncOfflineStaffChanges = async () => {
  if (!navigator.onLine) return;

  const offlineChanges = await db.getOfflineChanges();
  for (const change of offlineChanges) {
    store.commit("startSync", true);
    try {
      if (change.type === "addStaff") {
        await addStaff(change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "editStaff") {
        await editStaff(change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }
      if (change.type === "deleteStaff") {
        await deleteStaff(change.item);
        await db.deleteOfflineChange(change.iDBkey);
      }

      store.dispatch("endSync");
    } catch (error) {
      console.error(`Failed to sync change for ${change.STORE_NAME}:`, error);
    }
  }
};

export const updateProfile = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/update/profile`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update profile: ${error}`,
      });
      console.error(`Failed to update profile:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update profile offline.`,
    });
  }
};

export const updateRole = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/update/role`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update role: ${error}`,
      });
      console.error(`Failed to update role:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update role offline.`,
    });
  }
};

export const updateSupervisor = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/update/immediate-supervisor`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update immediate supervisor: ${error}`,
      });
      console.error(`Failed to update immediate supervisor:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update immediate supervisor offline.`,
    });
  }
};

export const updateEmail = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/update/email`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update official email: ${error}`,
      });
      console.error(`Failed to update official email:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update official email offline.`,
    });
  }
};

export const updateDesignation = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/update/designation`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to update designation: ${error}`,
      });
      console.error(`Failed to update designation:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot update designation offline.`,
    });
  }
};

export const staffLeft = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/left`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to mark staff as left: ${error}`,
      });
      console.error(`Failed to mark staff as left:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot mark staff as left offline.`,
    });
  }
};

export const staffRejoin = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/rejoin`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to make staff rejoin: ${error}`,
      });
      console.error(`Failed to make staff rejoin:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot make staff rejoin offline.`,
    });
  }
};

export const activateStaff = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/activate`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to activate staff: ${error}`,
      });
      console.error(`Failed to activate staff:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot activate staff offline.`,
    });
  }
};

export const deactivateStaff = async (item) => {
  if (navigator.onLine) {
    const url = API_URL + `/v1/deactivate`;
    const params = item;
    const headers = {};
    const method = "post";

    try {
      const response = await axiosInstance({
        method,
        url,
        data: params,
        headers: {
          ...headers,
        },
      });

      if (response.data == null) {
        store.commit("setError", { isError: true, error: "Item not found." });
      } else if (response.data.error) {
        store.commit("setError", { isError: true, error: response.data.error });
      } else {
        store.commit("setError", {
          isError: false,
          error: response.data.message,
        });
        return "success";
      }
    } catch (error) {
      store.commit("setError", {
        isError: true,
        error: `Failed to deactivate staff: ${error}`,
      });
      console.error(`Failed to deactivate staff:`, error);
    }
  } else {
    store.commit("setError", {
      isError: true,
      error: `Cannot deactivate staff offline.`,
    });
  }
};
